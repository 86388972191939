exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-thanks-js": () => import("./../../../src/pages/about/thanks.js" /* webpackChunkName: "component---src-pages-about-thanks-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-insights-index-js": () => import("./../../../src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-kamsa-js": () => import("./../../../src/pages/kamsa.js" /* webpackChunkName: "component---src-pages-kamsa-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-bob-integration-js": () => import("./../../../src/templates/bob-integration.js" /* webpackChunkName: "component---src-templates-bob-integration-js" */),
  "component---src-templates-career-listing-js": () => import("./../../../src/templates/career-listing.js" /* webpackChunkName: "component---src-templates-career-listing-js" */),
  "component---src-templates-compensation-consulting-services-page-js": () => import("./../../../src/templates/compensation-consulting-services-page.js" /* webpackChunkName: "component---src-templates-compensation-consulting-services-page-js" */),
  "component---src-templates-compensation-review-budgeting-planning-page-js": () => import("./../../../src/templates/compensation-review-budgeting-planning-page.js" /* webpackChunkName: "component---src-templates-compensation-review-budgeting-planning-page-js" */),
  "component---src-templates-employee-workforce-analytics-page-js": () => import("./../../../src/templates/employee-workforce-analytics-page.js" /* webpackChunkName: "component---src-templates-employee-workforce-analytics-page-js" */),
  "component---src-templates-equity-page-js": () => import("./../../../src/templates/equity-page.js" /* webpackChunkName: "component---src-templates-equity-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-global-market-compensation-data-page-js": () => import("./../../../src/templates/global-market-compensation-data-page.js" /* webpackChunkName: "component---src-templates-global-market-compensation-data-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-pricing-sheet-js": () => import("./../../../src/templates/pricing-sheet.js" /* webpackChunkName: "component---src-templates-pricing-sheet-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-request-demo-page-js": () => import("./../../../src/templates/request-demo-page.js" /* webpackChunkName: "component---src-templates-request-demo-page-js" */),
  "component---src-templates-security-page-js": () => import("./../../../src/templates/security-page.js" /* webpackChunkName: "component---src-templates-security-page-js" */),
  "component---src-templates-subprocessors-page-js": () => import("./../../../src/templates/subprocessors-page.js" /* webpackChunkName: "component---src-templates-subprocessors-page-js" */),
  "component---src-templates-support-article-js": () => import("./../../../src/templates/support-article.js" /* webpackChunkName: "component---src-templates-support-article-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

